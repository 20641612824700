// import { useContext } from 'react';

// // auth provider
// import AuthContext from 'contexts/JWTContext';

// // ==============================|| AUTH HOOKS ||============================== //

// const useAuth = () => {
//   const context = useContext(AuthContext);

//   if (!context) throw new Error('context must be use inside provider');

//   return context;
// };

// export default useAuth;

import AuthContext from 'contexts/JWTContext';
import { createContext, useContext, useState } from 'react';

// Create a new context for Auth


// Auth provider component
export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);
    console.log(setAuthData)
  // Your authentication logic here, setting `authData` when authenticated
console.log(createContext)
  return (
    <AuthContext.Provider value={authData}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for using the Auth context
const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default useAuth;

