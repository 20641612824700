import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
// import AuthGuard from 'utils/route-guard/AuthGuard';
// import Dashboard from 'pages/dashboard_1';
// import ChargingSession from 'pages/chargingsession';
// import TransferPayment from 'pages/transferpayment';
// import Info from 'pages/info';
// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const Dashboard = Loadable(lazy(() => import('pages/dashboard_1')));
const ChargingSession = Loadable(lazy(() => import('pages/chargingsession')));
const TransferPayment = Loadable(lazy(() => import('pages/transferpayment')));
const Info = Loadable(lazy(() => import('pages/info')));
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const SettingValue = Loadable(lazy(() => import('pages/setting_value')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        // <AuthGuard>
          <MainLayout />
        //  </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'chargingsession',
          element: <ChargingSession/>
        },
        {
          path: 'transferpayment',
          element: <TransferPayment/>
        },
        {
          path: 'info',
          element: <Info/>
        },
        {
          path: 'settingvalue',
          element: <SettingValue/>
        },
        {
          path: 'sample-page',
          element: <SamplePage />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
