
export const Url_Login = "https://be.fcchargeev.com/dashboardapi/login" ;
export const Url = "https://be.fcchargeev.com/" ;



export const service = {
    WEEKLY_PLOT: "dashboardapi/dashboard/weekly",
    STATUS_TABLE: "dashboardapi/dashboard/statusonline",
    TEN_TICKET:"dashboardapi/dashboard/ten",
    ALL_TICKET: "dashboardapi/dashboard/allticket",
    TRANSFER: "dashboardapi/dashboard/transfer",
    INFO_USER_ALL: "dashboardapi/dashboard/userinfoall",
    CHARGELIST_DETAIL: "dashboardapi/dashboard/chargerlistdetail",
    INCOME_SUMMARY: "dashboardapi/dashboard/incomesummary",
    CHARGERLIST: "dashboardapi/dashboard/chargerlist",
    EDIT_CHARGER: "dashboardapi/dashboard/editcharger"
  };
