// project import
import menupath from './menupath';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [menupath]
};
// const menuItems = {
//   items: [other]
// };

export default menuItems;
